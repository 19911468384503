import React, { useRef, useEffect } from "react"
import introVideo from "../assets/video/intro.mp4"
import { GatsbySeo } from "gatsby-plugin-next-seo"
import { navigate } from "gatsby"
import { useTranslation } from "react-i18next"
import { graphql } from "gatsby"

export default function Qr() {
  const { i18n } = useTranslation() // Get translation info
  const videoRef = useRef(null)

  useEffect(() => {
    const video = videoRef.current

    const handleUserInteraction = () => {
      if (video && video.paused) {
        video.play()
      }
      document.removeEventListener("touchstart", handleUserInteraction)
    }

    document.addEventListener("touchstart", handleUserInteraction)

    return () => {
      document.removeEventListener("touchstart", handleUserInteraction)
    }
  }, [])

  const handleVideoEnded = () => {
    if (i18n.language === "en") {
      navigate("/home")
    } else {
      navigate(`/${i18n.language}/home`)
    }
  }

  return (
    <>
      {/* SEO Metadata */}
      <GatsbySeo
        title="QR"
        description="Arcanode is expertise and innovation for retail, wholesalers, and IT companies; it’s the secure and high-performing solution that empowers businesses without compromising on cost and quality."
        canonical="https://arcanode.io/qr/"
        noindex={true}
        openGraph={{
          type: "video.movie",
          url: "https://arcanode.io/qr/",
          title: "Arcanode QR",
          description:
            "Expertise and innovation for retail, wholesalers, and IT companies; secure and high-performing solutions without compromising on cost and quality.",
          videos: [
            {
              url: "https://arcanode.io/static/intro-c0de2d4306ec412ef0c99baaf40fd2e6.mp4",
              width: 1920,
              height: 1080,
              alt: "Intro Video",
            },
          ],
        }}
      />

      {/* Page Layout */}
      <div
        style={{ backgroundColor: "#3A3938" }}
        className="h-screen w-screen overflow-hidden flex items-center justify-center"
      >
        <video
          ref={videoRef}
          src={introVideo}
          autoPlay
          onEnded={handleVideoEnded}
          className="w-full h-full object-cover"
          muted
          playsInline
        />
      </div>
    </>
  )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
